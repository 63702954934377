@font-face {
    font-family: 'Monument Grotesk';
    src: url('../assets/fonts/MonumentGrotesk-Regular.woff2') format('woff2'),
    url('../assets/fonts/MonumentGrotesk-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Monument Grotesk';
    src: url('../assets/fonts/MonumentGrotesk-Italic.woff2') format('woff2'),
    url('../assets/fonts/MonumentGrotesk-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Monument Grotesk';
    src: url('../assets/fonts/MonumentGrotesk-Medium.woff2') format('woff2'),
    url('../assets/fonts/MonumentGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Monument Grotesk';
    src: url('../assets/fonts/MonumentGrotesk-MediumItalic.woff2') format('woff2'),
    url('../assets/fonts/MonumentGrotesk-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Monument Grotesk';
    src: url('../assets/fonts/MonumentGrotesk-Bold.woff2') format('woff2'),
    url('../assets/fonts/MonumentGrotesk-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Monument Grotesk';
    src: url('../assets/fonts/MonumentGrotesk-BoldItalic.woff2') format('woff2'),
    url('../assets/fonts/MonumentGrotesk-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

html {
    min-height: 100vh;
    background: #FBFBFB;
}

.fade-enter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    opacity: 0;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    opacity: 1;
    transition: opacity 250ms ease-in;
}

#root {
    overflow: hidden;
}
